@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,600;0,800;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
.font {
  font-family: "Jost", sans-serif;
}
.font1 {
  font-family: "Jost", sans-serif;
}
.polka {
  background-image: radial-gradient(rgb(30, 64, 175) 18.8%, transparent 20.8%),
    radial-gradient(rgb(30, 64, 175) 18.8%, transparent 20.8%);
  background-position: 0px 0px, 20px 20px;
  background-size: 40px 40px;
  background-color: #fafaf9;
}

.border-move-animation::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: white;
  transition: width 0.3s;
}

.border-move-animation:hover::after {
  width: 100%;
  transition: width 0.3s;
}
.border-move-animation-footer::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: black;
  transition: width 0.3s;
}

.border-move-animation-footer:hover::after {
  width: 100%;
  transition: width 0.3s;
}

.bubble {
  background: radial-gradient(
    circle,
    rgb(82, 142, 239) 0%,
    rgba(29, 78, 216, 1) 66%
  );
  border-bottom-left-radius: 30% 30%;
  border-bottom-right-radius: 30% 30%;
  height: 45vh;
  width: 100%;
}
@media (max-width: 768px) {
  .bubble {
    height: 70vh;
  }
}
